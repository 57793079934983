import React, { useContext } from 'react'
import CampingContext from '../../../contexts/campingData/campingData'
import {
  getCurrentVideo,
  getIdsByCategory,
  getVideosByIds,
  randomizeVideos,
} from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeCampingVideosForSinglePostByState = (
  selectedState,
  allReviews,
  allVideos,
  reviewId
) => {
  const currentVideo = getCurrentVideo(allVideos, reviewId)
  const otherVideosByState = randomizeVideos(
    getVideosByIds(
      getIdsByCategory(allReviews, selectedState).filter(
        video => video !== reviewId
      ),
      allVideos
    )
  )

  return [...currentVideo, ...otherVideosByState]
}
const CampingVideosForSinglePostByState = ({ selectedState, reviewId }) => {
  const allVideos = useContext(VideoContext)
  const allReviews = useContext(CampingContext)

  return (
    <SuggestedVideos
      videos={computeCampingVideosForSinglePostByState(
        selectedState,
        allReviews,
        allVideos,
        reviewId
      )}
    />
  )
}

CampingVideosForSinglePostByState.propTypes = {
  selectedState: PropTypes.string,
  reviewId: PropTypes.string,
}

export default CampingVideosForSinglePostByState
