import React from 'react'
import AllVideosForSinglePost from '../../components/SuggestedVideos/AllVideosForSinglePost/AllVideosForSinglePost'
import ArticleButton from '../../components/sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../components/sharedComponents/ArticleTitle/ArticleTitle'
import { BlogData } from '../../contexts/blogData/blogData'
import { CampingData } from '../../contexts/campingData/campingData'
import CampingVideosForSinglePostByState from '../../components/SuggestedVideos/CampingVideosForSinglePostByState/CampingVideosForSinglePostByState'
import CategoryLinkSelector from '../../components/Selectors/CategoryLinkSelector/CategoryLinkSelector'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { LodgingData } from '../../contexts/lodgingData/lodgingData'
import NearbyActivities from '../../components/NearbyActivities/NearbyActivities'
import SEO from '../../components/seo'
import SideBar from '../../components/sideBar/SideBar'
import SubHeading from '../../components/sharedComponents/SubHeading/SubHeading'
import TwoColumnLayout from '../../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import styles from './camping.module.css'
import AdSense from 'react-adsense'
import MediaQuery from '../../components/sharedComponents/MediaQuery/MediaQuery'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const CampingReview = ({ data, location }) => {
  const {
    seo_title,
    seo_description,
    placeId,
    slug,
    title,
    author,
    displayName,
    categories,
    date,
    nearby,
    alt,
    blogType,
    videoId,
    summary,
    thumbnail,
  } = data.markdownRemark.frontmatter
  const {
    site: {
      siteMetadata: { url, twitterHandle },
    },
  } = data

  const fullSlug = data.markdownRemark.fields.slug
  const selectedState = location.state ? location.state.currentCategory : null
  const { html } = data.markdownRemark
  const fluidImg = getFluidImage(
    data.markdownRemark.frontmatter,
    data.allFile.nodes
  )

  const socialMediaConfig = {
    twitterHandle,
    config: {
      title,
      url: `${url}${fullSlug}`,
      media: `${url}${thumbnail}`,
      description: summary,
    },
  }
  const matchPoints = MediaQuery(queries)
  return (
    <>
      <SEO
        title={seo_title}
        description={seo_description}
        thumbnail={thumbnail}
        url={`${url}${fullSlug}`}
        type="article"
      />
      <TwoColumnLayout>
        <BlogData>
          <CampingData>
            <LodgingData>
              <VideoData>
                <div>
                  <ArticleTitle>{title}</ArticleTitle>
                  <SubHeading
                    author={author}
                    date={date}
                    socialMediaConfig={socialMediaConfig}
                    categories={categories}
                  />
                  <div className={styles.imageContainer}>
                    <Img fluid={fluidImg} alt={alt} />
                  </div>
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  <div className={styles.moreInfo}>
                    Interested in learning more about{' '}
                    <span className={styles.moreInfoDisplayName}>
                      {displayName}
                    </span>
                    ? Consider checking out our video, or get detailed
                    information about this campground including prices, list of
                    amenities, directions and much more. Links below.
                  </div>
                  <div className={styles.articleLinks}>
                    <ArticleButton
                      icon="read"
                      url={`/information/${slug}`}
                      color="#b63d2b"
                      text="Information"
                    />
                    <ArticleButton
                      icon="video"
                      url="/videos"
                      videoId={videoId}
                      color="#9f211c"
                      text="Watch Our Video"
                    />
                  </div>
                  <NearbyActivities
                    distances={
                      data.allDistanceMatrixData.edges[0].node.distanceMatrix
                    }
                    placeId={placeId}
                    allImages={data.allFile.nodes}
                    nearby={nearby}
                  />
                </div>
                <SideBar>
                  {selectedState ? (
                    <CampingVideosForSinglePostByState
                      reviewId={videoId}
                      selectedState={selectedState}
                    />
                  ) : (
                    <AllVideosForSinglePost
                      type={blogType}
                      reviewId={videoId}
                    />
                  )}
                  <CategoryLinkSelector />
                  {matchPoints && matchPoints.desktop ? (
                    <AdSense.Google
                      client="ca-pub-1156710604979342"
                      slot="2311836324"
                      style={{
                        display: 'inline-block',
                        width: '300px',
                        height: '250px',
                        position: 'sticky',
                        top: '184px',
                      }}
                    />
                  ) : null}
                </SideBar>
              </VideoData>
            </LodgingData>
          </CampingData>
        </BlogData>
      </TwoColumnLayout>
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        seo_title
        seo_description
        alt
        author
        slug
        displayName
        title
        date
        thumbnail
        summary
        blogType
        layout
        videoId
        categories
        state
        nearby
        placeId
      }
      fields {
        slug
      }
    }
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 1210) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    allDistanceMatrixData {
      edges {
        node {
          distanceMatrix {
            rows {
              elements {
                distance {
                  text
                  value
                }
                status
              }
            }
            destination_ids
            origin_ids
          }
        }
      }
    }
  }
`

CampingReview.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default CampingReview
